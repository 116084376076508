export default {
  s3: {
    REGION: 'YOUR_S3_UPLOADS_BUCKET_REGION',
    BUCKET: 'YOUR_S3_UPLOADS_BUCKET_NAME',
  },
  apiGateway: {
    // HNB
    REGION: "us-east-1",
    URL: "https://002z2gemqa.execute-api.us-east-1.amazonaws.com/Prod"

    // DEV
    //REGION: 'us-west-2',
    //URL: 'https://ttrl5cqr8d.execute-api.us-west-2.amazonaws.com/Prod',

    // TEST
    //REGION: "us-west-2",
    //URL: "https://s13hqsrv0b.execute-api.us-west-2.amazonaws.com/Prod"

    // QA
    //REGION: 'us-west-2',
    //URL: 'https://tdhkaa6hp5.execute-api.us-west-2.amazonaws.com/Prod',

    //demo
    //REGION: "us-west-2",
    //URL: "https://pa4dv5gydj.execute-api.us-west-2.amazonaws.com/Prod"
  },
  cognito: {
    // QA
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_MqLCPVyUv',
    APP_CLIENT_ID: '1svk03r22f7f3lpbtfkqjgfpop',
    IDENTITY_POOL_ID: 'us-east-1:4c935a50-43eb-4e15-84e3-ef016259ec07',

    // DEV
    //REGION: 'us-west-2',
    //USER_POOL_ID: 'us-west-2_Y4JVIzqBd',
    //APP_CLIENT_ID: '731kdlsff826boli6jhsqlr6lh',
    //IDENTITY_POOL_ID: 'us-west-2:5f829c36-a080-4971-928d-a86694e1b987',

    // test
    //REGION: "us-west-2",
    //USER_POOL_ID: "us-west-2_ZfrtX8tic",
    //APP_CLIENT_ID: "test_app_clientWeb",
    //IDENTITY_POOL_ID: "us-west-2:d30384ba-b3c5-40d3-88d0-b57643b3a0b4"

    // QA
    //REGION: 'us-west-2',
    //USER_POOL_ID: 'us-west-2_KHr7PLanR',
    //APP_CLIENT_ID: '10dhel8no327i8e2kdh0shfva4',
    //IDENTITY_POOL_ID: 'us-west-2:0d9bae17-cb70-4c6b-b03a-0a6f6812a8c2',

    // demo
    //REGION: "us-west-2",
    //USER_POOL_ID: "us-west-2_zVhLChmNO",
    //APP_CLIENT_ID: "rc3vamm9249uiuju2df375tt0",
    //IDENTITY_POOL_ID: "us-west-2:67340aba-d013-4b43-9872-462ee5fa129f"
  },
  intuit: {
    CLIENT_ID: 'L0GPj34mOT9n7iYLOoAXWIvXcdUJg8lusuwBMPYHUg2T5hZuzQ',
    // AMPLIFY
    // DEV - LOCALHOST
    //    REDIRECT_URI: "%3Dhttp%253a%252f%252flocalhost%253a3000%252f"

    // https://master.d1umhw82yw2r36.amplifyapp.com
  },
  environment: {
    // DEV
    //APP_PREFIX: 'cae',
    // TEST
    //APP_PREFIX: "test"
    // QA
    APP_PREFIX: 'QA',
    // demo
    //APP_PREFIX: "demo"
  },
  bank: {
    ACCOUNT_LENGTH: '15',
    // 1 min
    //TIMEOUT: 60000
    // 5 mins
    //TIMEOUT: 300000
    // 10 mins
    //TIMEOUT: 600000
    // 60 mins
    TIMEOUT: 3600000,
    SSO: 'false',
    SSO_USER: 'false',
    PRODUCT_NAME: 'Cloud Easy Export Administration',
    BANK_NAME: 'Huntington',
  },
};
