import React from "react";
import { API } from "aws-amplify";
import { Button } from "react-bootstrap";
import config from '../config';
import "./Customers.css";
import Moment from "react-moment";
import "moment-timezone";
import ErrorDialog from "./ErrorDialog";
import LoadingDialog from "./LoadingDialog";
import ConfirmDialog from "./ConfirmDialog";
import "../css/branding.css";
import TimeoutComponent from "../components/TimeoutComponent";
import BootstrapTable from "react-bootstrap-table-next";

class Customers extends TimeoutComponent {
  constructor(props) {
    super(props);

    this.customerToDelete = "";
    this.state = { customers: [], page: "" };

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.onClick_Delete = this.onClick_Delete.bind(this);
    this.deleteCustomer = this.deleteCustomer.bind(this);
    this.handleAnswer = this.handleAnswer.bind(this);
  }

  async componentDidMount() {
    console.log("componentDidMount()");

    try {
      this.refs.loadingDialog.show(true);

      this.setState({ customers: [] });

      var token = "";
      var userArray = [];

      do {
        var reply = await this.getCustomerList(token);

        if (reply.error)
        {
          throw new Error(reply.error);
        }         

        userArray = userArray.concat(reply.users);
        token = reply.pagination_token;
      } while (token !== undefined);

      this.setState({ customers: userArray });
    } catch (e) {
      console.log("catch");
      this.refs.errorDialog.displayError("Error", e.message);
    } finally {
      console.log("finally");
      this.refs.loadingDialog.show(false);
    }
  }

  getCustomerList(token) {
    console.log("getCustomerList()");

    if (token.length > 0) {
      return API.get("cloudautocheckissues", "/adminUser", {
        queryStringParameters: {
          limit: 60,
          pagination_token: token,
        },
      });
    } else {
      return API.get("cloudautocheckissues", "/adminUser", {
        queryStringParameters: {
          limit: 60,
        },
      });
    }
  }

  deleteCustomer(user_id) {
    console.log("deleteCustomer()");

    return API.del("cloudautocheckissues", "/adminUser", {
      queryStringParameters: {
        user_id: user_id,
      },
    });
  }

  async onClick_AddCustomer(event) {
    this.props.history.push("/addcustomer");
  }

  async onClick_ResendEmail(row) {
    try {
      this.refs.loadingDialog.show(true);

      var body = {
        body: {
          email: row.email,
          resend: true,
        },
      };

      var apiResponse = await API.post(
        "cloudautocheckissues",
        "/adminUser",
        body
      );
    } catch (e) {
      this.refs.errorDialog.displayError("Error", e.message);
    } finally {
      this.refs.loadingDialog.show(false);
    }
  }

  async handleAnswer(answer) {
    if (answer) {
      try {
        this.refs.loadingDialog.show(true);

        var reply = await this.deleteCustomer(this.customerToDelete);

        this.setState({ customers: [] });

        var token = "";
        var userArray = [];

        do {
          var reply = await this.getCustomerList(token);

          if (reply.error)
          {
            throw new Error(reply.error);
          }         

          userArray = userArray.concat(reply.users);
          token = reply.pagination_token;
        } while (token !== undefined);

        this.setState({ customers: userArray });
      } catch (e) {
        this.refs.errorDialog.displayError("Error", e.message);
      } finally {
        this.refs.loadingDialog.show(false);
      }
    }
    this.customerToDelete = "";
  }

  onClick_ActivityReport(user_id) {
    this.props.history.push({
      pathname: "/activityreportcriteria",
    });
  }

  onClick_Report(user_id) {
    this.props.history.push({
      pathname: "/auditreportcriteria",
      user_id: user_id,
    });
  }

  onClick_Edit(row) {
    this.props.history.push({
      pathname: "/editcustomer",
      user: row,
    });
  }

  onClick_Delete(row) {
    this.customerToDelete = row.user_id;
    this.refs.confirmDialog.displayConfirmBox(
      "Confirm Delete",
      "Are you sure you want to delete the customer '" +
        row.company_name +
        "'?",
      this.handleAnswer
    );
  }

  render() {
    const columns = [     
      {
        dataField: "status",
        isDummyField: true,
        text: "Status",
        formatter: (cellContent, row) => {
          if (row.files === undefined) {
            return (
              <h5>
                <span className="label label-warning">Setup Incomplete</span>
              </h5>
            );
          } else {
            var files = row.files;
            if (files.length == 0) {
              return (
                <h5>
                  <span className="label label-warning">Setup Incomplete</span>
                </h5>
              );
            }
            for (let i = 0; i < files.length; i++) {
              if (files[i].errors > 0) {
                return (
                  <h5>
                    <span className="label label-danger">Error</span>
                  </h5>
                );
              }
            }
            return (
              <h5>
                <span className="label label-success">Connected</span>
              </h5>
            );
          }
        },
      }, 
      {
        dataField: "email",
        text: "Contact Email",
        sort: true,
      },
      {
        dataField: "company_name",
        text: "Company Name",
        sort: true,
      },      
      {
        dataField: "last_name",
        text: "Contact Name",
        formatter: (cell, row) => {
          console.log(row);
          return <div>{`${row.last_name}, ${row.first_name}`}</div>;
        },
      },
      {
        dataField: "created",
        text: "Created",
        formatter: (cell, row) => {
          console.log(row);
          var newStr = new Date(row.created).toLocaleString("en-US", {
            timeZone: this.timezone,
          });
          return <span style={{ whiteSpace: "pre-wrap" }}>{`${newStr}`}</span>;

          //      return <Moment parse='YY/MM/DD hh:mm:ss' format='MM/DD/YYYY hh:mm A' tz={Intl.DateTimeFormat().resolvedOptions().timeZone} date={row.created} />;
        },
      },          
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: (cellContent, row) => {
          if (row.files === undefined) {
            return (
              <div>
                <Button
                  style={{ marginRight: "5px", marginTop: "5px" }}
                  onClick={() => {
                    this.onClick_Report(row.email);
                  }}
                >
                  <span className="glyphicon glyphicon-file"></span>
                </Button>

                { config.bank.SSO_USER === 'false' &&
                <Button
                  style={{ marginRight: "5px", marginTop: "5px" }}
                  onClick={() => {
                    this.onClick_Edit(row);
                  }}
                >
                  <span className="glyphicon glyphicon-pencil"></span>
                </Button>
                }
                
                <Button
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    this.onClick_Delete(row);
                  }}
                >
                  <span className="glyphicon glyphicon-trash"></span>
                </Button>
              </div>
            );
          } else {
            return (
              <div>
                <Button
                  style={{ marginRight: "5px", marginTop: "5px" }}
                  onClick={() => {
                    this.onClick_Report(row.email);
                  }}
                >
                  <span className="glyphicon glyphicon-file"></span>
                </Button>

                { config.bank.SSO_USER === 'false' &&
                <Button
                  style={{ marginRight: "5px", marginTop: "5px" }}
                  onClick={() => {
                    this.onClick_Edit(row);
                  }}
                >
                  <span className="glyphicon glyphicon-pencil"></span>
                </Button>
                }

                <Button
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    this.onClick_Delete(row);
                  }}
                >
                  <span className="glyphicon glyphicon-trash"></span>
                </Button>
              </div>
            );
          }
        },
      },
    ];

    const columns2 = [
      {
        dataField: "company_name",
        text: "Connected Company",
        headerStyle: {
          backgroundColor: "lightblue",
        },
      },
      {
        dataField: "last_run",
        text: "Last Check Retrieval",
        headerStyle: {
          backgroundColor: "lightblue",
        },
        formatter: (cell, row) => {
          console.log(row);
          if (row.last_run !== undefined) {
            var newStr = new Date(row.last_run).toLocaleString("en-US", {
              timeZone: this.timezone,
            });
            return (
              <span style={{ whiteSpace: "pre-wrap" }}>{`${newStr}`}</span>
            );

            //        return <Moment format='M/D/YYYY, h:mm:ss A' date={row.last_run} />;
          } else {
            return <p>Awaiting First Retrieval</p>;
          }
        },
      },
      {
        dataField: "last_run_status",
        text: "Results",
        headerStyle: {
          backgroundColor: "lightblue",
        },
        formatter: (cell, row) => {
          console.log(row);
          if (row.last_run !== undefined) {
            var numSent;
            var numNoData;
            var numErrors;
            row.sent !== undefined ? (numSent = row.sent) : (numSent = "NA");
            row.no_data !== undefined
              ? (numNoData = row.no_data)
              : (numNoData = "NA");
            row.errors !== undefined
              ? (numErrors = row.errors)
              : (numErrors = "NA");
            return (
              <div>{`SENT: ${numSent}, NO DATA: ${numNoData}, ERRORS: ${numErrors}`}</div>
            );
          } else {
            return <p>N/A</p>;
          }
        },
      },
      {
        dataField: "service",
        text: "Accounting System",
        headerStyle: {
          backgroundColor: "lightblue",
        },
      },
    ];

    const expandRow = {
      renderer: (row) => (
        <div style={{ marginLeft: "50px" }}>
          {row.files !== undefined && row.files.length > 0 && (
            <BootstrapTable
              keyField="company_name"
              data={row.files}
              columns={columns2}
              rowStyle={{ backgroundColor: "whitesmoke" }}
            />
          )}
          {row.files !== undefined && row.files.length === 0 && (
            <div
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <b>No Company Files have been set up.</b>
            </div>
          )}
          {row.files === undefined && (
            <div
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <b>User has not signed in. Click</b>
              <Button
                style={{ marginLeft: "10px", marginRight: "10px" }}
                onClick={() => {
                  this.onClick_ResendEmail(row);
                }}
              >
                <span className="glyphicon glyphicon-envelope"></span>
              </Button>
              <b>to resend the Welcome email with a new password.</b>
            </div>
          )}
        </div>
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
    };

    return (
      <div className="UpcomingDeliveries">
        <div
          style={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3 style={{ width: "100%" }}>User Maintenance</h3>
          <Button
            style={{ marginRight: "5px" }}
            onClick={() => {
              this.componentDidMount();
            }}
          >
            <span className="glyphicon glyphicon-repeat"></span>
          </Button>

          <Button
            style={{ marginRight: "5px" }}
            onClick={() => {
              this.onClick_ActivityReport();
            }}
          >
            <span className="glyphicon glyphicon-file"></span>
          </Button>

          { config.bank.SSO_USER === 'false' &&
          <Button
            bsStyle="default"
            bsSize="small"
            onClick={() => {
              this.onClick_AddCustomer();
            }}
          >
            Add User
          </Button>
          }

        </div>
{/*
        <p style={{ marginBottom: "20px" }}>
          The following customers have been added to the Auto Check Issues service.
        </p>
*/}
        <p style={{ marginBottom: "20px" }}>
          Click the Expand icon to view additional information for a user. Click the Report button to view activity for a user. Click the Edit icon to update a user's company or contact information.
        </p>

        <div className="widget" style={{ width: "100%", height: "100%" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <BootstrapTable
              keyField="email"
              data={this.state.customers}
              columns={columns}
              expandRow={expandRow}              
              sort={{ dataField: "company_name", order: "asc" }}
            />
          </div>
        </div>

        <ErrorDialog ref="errorDialog" />
        <LoadingDialog ref="loadingDialog" />
        <ConfirmDialog ref="confirmDialog" />
      </div>
    );
  }
}

export default Customers;
